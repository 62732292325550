import { ApplicationGroup } from 'switch-shared/modules/crud/application-group/application-group.entity';
import { ENDPOINT_TAGS } from '../config/apiTags';
import BaseApiService, { ResultWithHttpCode, ResultWithValueAndHttpCode } from './base.service';

class TraderApiService extends BaseApiService {
  constructor() {
    super(process.env.REACT_APP_BASE_API_URL);
  }

  public async getTraders(params: {
    applicationGroupId?: string;
    page?: number;
    limit?: number;
  }): Promise<ResultWithValueAndHttpCode<ApplicationGroup[]> | undefined> {
    const apiResult: ResultWithValueAndHttpCode<any> | undefined = await this.getWithParams<any>(
      `${ENDPOINT_TAGS.APPLICATION_GROUPS}`,
      params
    );
    if (apiResult !== undefined) {
      if (!apiResult.isSuccess) {
        return await Promise.reject(
          new Error(
            JSON.stringify({
              isSuccess: false,
              value: null,
              errorMessage: apiResult.errorMessage,
              httpCode: apiResult.httpCode,
            })
          )
        );
      }
      return {
        isSuccess: true,
        value: apiResult.value?.data,
        errorMessage: '',
        httpCode: apiResult.httpCode,
      };
    }
  }

  public async createTrader(newAppObject: {}): Promise<ResultWithValueAndHttpCode<any> | undefined> {
    const apiResult: ResultWithValueAndHttpCode<any> | undefined = await this.post<any>(
      `${ENDPOINT_TAGS.APPLICATION_GROUPS}`,
      newAppObject
    );
    if (apiResult !== undefined) {
      if (!apiResult.isSuccess) {
        return await Promise.reject(
          new Error(
            JSON.stringify({
              isSuccess: false,
              value: null,
              errorMessage: apiResult.errorMessage,
              httpCode: apiResult.httpCode,
            })
          )
        );
      }

      return {
        isSuccess: true,
        value: newAppObject,
        errorMessage: '',
        httpCode: apiResult.httpCode,
      };
    }
  }

  public async deleteTrader(traderId: string): Promise<ResultWithHttpCode | undefined> {
    const apiResult: ResultWithHttpCode | undefined = await this.delete(`${ENDPOINT_TAGS.APPLICATION_GROUPS}/${traderId}`);
    if (apiResult !== undefined) {
      if (!apiResult.isSuccess) {
        return await Promise.reject(
          new Error(
            JSON.stringify({
              isSuccess: false,
              value: null,
              errorMessage: apiResult.errorMessage,
              httpCode: apiResult.httpCode,
            })
          )
        );
      }
      return {
        isSuccess: true,
        errorMessage: '',
        httpCode: apiResult.httpCode,
      };
    }
  }

  public async updateTrader(trader: ApplicationGroup): Promise<ResultWithValueAndHttpCode<any> | undefined> {
    const { _id } = trader;
    const apiResult: ResultWithValueAndHttpCode<any> | undefined = await this.patch<any>(
      `${ENDPOINT_TAGS.APPLICATION_GROUPS}/${_id}`,
      trader
    );
    if (apiResult !== undefined) {
      if (!apiResult.isSuccess) {
        return await Promise.reject(
          new Error(
            JSON.stringify({
              isSuccess: false,
              value: null,
              errorMessage: apiResult.errorMessage,
              httpCode: apiResult.httpCode,
            })
          )
        );
      }
      return {
        isSuccess: true,
        value: apiResult.value,
        errorMessage: '',
        httpCode: apiResult.httpCode,
      };
    }
  }
}

export default TraderApiService;
