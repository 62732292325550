import { baseAPI as api } from '../api.service';
import { API_METHODS, ENDPOINT_TAGS } from '../config/apiTags';
import { CreateDto } from "switch-trading-shared/modules/crud/base.service";
import { FindDto } from "switch-trading-shared/modules/crud/base.service";
import { TradeGroup } from "switch-trading-shared/modules/crud/trade-groups/trade-group.entity";
import { UpdateDto } from "switch-trading-shared/modules/crud/base.service";

const tradeGroupApi = api
  .enhanceEndpoints({
    addTagTypes: [ENDPOINT_TAGS.TRADE_GROUP],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createTradeGroup: build.mutation<TradeGroup, CreateDto<TradeGroup>>({
        query: (body) => ({
          url: 'trade-group',
          method: API_METHODS.POST,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.TRADE_GROUP],
      }),

      findTradeGroup: build.query<TradeGroup[], FindDto<TradeGroup>>({
        query: (params) => ({
          url: 'trade-group/find',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.TRADE_GROUP],
      }),

      findOneTradeGroup: build.query<TradeGroup, FindDto<TradeGroup>>({
        query: (params) => ({
          url: 'trade-group/findOne',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.TRADE_GROUP],
      }),

      findTradeGroupById: build.query<TradeGroup, { id: string }>({
        query: ({ id }) => ({
          url: `trade-group/findById/${id}`,
          method: API_METHODS.GET,
        }),
        providesTags: [ENDPOINT_TAGS.TRADE_GROUP],
      }),

      updateTradeGroup: build.mutation<TradeGroup, { id: string; body: UpdateDto<TradeGroup> }>({
        query: ({ id, body }) => ({
          url: `trade-group/${id}`,
          method: API_METHODS.PATCH,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.TRADE_GROUP],
      }),

      deleteTradeGroup: build.mutation<TradeGroup[], { id: string }>({
        query: ({ id }) => ({
          url: `trade-group/${id}`,
          method: API_METHODS.DELETE,
        }),
        invalidatesTags: [ENDPOINT_TAGS.TRADE_GROUP],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateTradeGroupMutation,
  useDeleteTradeGroupMutation,
  useUpdateTradeGroupMutation,
  useFindTradeGroupQuery,
  useFindOneTradeGroupQuery,
  useFindTradeGroupByIdQuery,
} = tradeGroupApi;

export default tradeGroupApi;
