import { createContext, ReactNode, useContext, useState } from 'react';
import { ApplicationGroup } from 'switch-shared/modules/crud/application-group/application-group.entity';

// Create the context
interface TraderContextType {
  pickedTrader: ApplicationGroup | null;
  selectTrader: (trader: ApplicationGroup | null) => void;
}

const TraderContext = createContext<TraderContextType | undefined>(undefined);

// Create a custom hook to use the context
export function useTrader() {
  const context = useContext(TraderContext);
  if (!context) {
    throw new Error('useTrader must be used within a TraderProvider');
  }
  return context;
}

type TraderProviderProps = {
  children: ReactNode;
};

export function TraderProvider({ children }: TraderProviderProps) {
  const [pickedTrader, setPickedTrader] = useState<ApplicationGroup | null>(() => {
    // Initialize the pickedTrader from localStorage, if available
    const traderFromLocalStorage = localStorage.getItem('pickedTrader');
    return traderFromLocalStorage ? JSON.parse(traderFromLocalStorage) : null;
  });

  const selectTrader = (trader: ApplicationGroup | null) => {
    setPickedTrader(trader);
    // Save the selected trader to localStorage
    if (trader) {
      localStorage.setItem('pickedTrader', JSON.stringify(trader));
    } else {
      // If trader is null (unselecting), remove it from localStorage
      localStorage.removeItem('pickedTrader');
    }
  };

  const value: TraderContextType = {
    pickedTrader,
    selectTrader,
  };

  return <TraderContext.Provider value={value}>{children}</TraderContext.Provider>;
}

export default TraderContext;
