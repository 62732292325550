import PropTypes from 'prop-types';
import { SetStateAction, useState } from 'react';

import { LogoutOutlined } from '@mui/icons-material';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Typography, colors, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: center;
        padding-left: ${theme.spacing(1)};
`
);

type ProfileTabProps = {
  handleLogout: () => void;
};
const ProfileTab = ({ handleLogout }: ProfileTabProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: SetStateAction<number>) => {
    setSelectedIndex(index);
  };

  return (
    <List
      component='nav'
      sx={{
        p: 0,
        '& .MuiListItemIcon-root': {
          minWidth: 32,
          color: theme.palette.grey[500],
        },
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* <ListItemButton
        sx={{
          borderRadius: '0',
        }}
        // selected={selectedIndex === 1}
        onClick={() => navigate(pages.account.listTraders.path)}
      >
        <ListItemIcon>
          <PeopleAlt />
        </ListItemIcon>
        <ListItemText primary='View All Traders' />
      </ListItemButton> */}
      {/* <ListItemButton
        sx={{
          borderRadius: '0',
        }}
        // selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(event, 0)}
      >
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary='Edit Profile' />
      </ListItemButton>
      <ListItemButton
        sx={{
          borderRadius: '0',
        }}
        // selected={selectedIndex === 1}
        onClick={(event) => handleListItemClick(event, 1)}
      >
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText primary='View Profile' />
      </ListItemButton> */}

      <ListItemButton
        sx={{
          borderRadius: '0',
        }}
        // selected={selectedIndex === 2}
        onClick={handleLogout}
      >
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary='Logout' />
      </ListItemButton>

      <UserBoxText sx={{ mt: 2 }}>
        <Typography
          variant='h6'
          align='center'
          component={RouterLink}
          to={`https://www.switch.org.za/`}
          target='_blank'
          sx={{
            color: colors.grey[500],
            fontSize: 12,
            justifyContent: 'center',
          }}
        >
          Powered by Switch Energy
        </Typography>
      </UserBoxText>
      <Typography
        sx={{
          color: colors.grey[500],
          fontSize: 12,
          mb: 1,
        }}
        align='center'
        component='p'
      >
        Version {packageJson.version}
      </Typography>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default ProfileTab;
