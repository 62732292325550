import { Breadcrumbs as MuiBreadCrumb } from "@mui/material";
import useBreadcrumbs from "use-react-router-breadcrumbs";

function Breadcrumbs({ routes }: { routes: any[] }) {
  const breadcrumbs = useBreadcrumbs(routes);

  // const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  //     event.preventDefault();
  //     console.log(event);
  //     console.info('You clicked a breadcrumb.');
  // };

  return (
    // <div role='presentation' onClick={handleClick}>
    <MuiBreadCrumb
      aria-label="breadcrumb"
      sx={{ color: (theme) => theme.colors.primary.main }}
    >
      {breadcrumbs.map(({ match, breadcrumb }: any, index: number) => {
        return <div key={index}>{breadcrumb}</div>;
      })}
    </MuiBreadCrumb>
    // </div>
  );
}

export default Breadcrumbs;
