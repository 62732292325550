import { baseAPI as api } from '../api.service';
import { API_METHODS, ENDPOINT_TAGS } from '../config/apiTags';
import { CreateDto } from "switch-trading-shared/modules/crud/base.service";
import { FindDto } from "switch-trading-shared/modules/crud/base.service";
import { Grid } from "switch-trading-shared/modules/crud/grids/grid.entity";
import { UpdateDto } from "switch-trading-shared/modules/crud/base.service";
import { SearchGridInput } from "switch-trading-shared/modules/crud/grids/grid.dto";

const GridAPI = api
  .enhanceEndpoints({
    addTagTypes: [ENDPOINT_TAGS.GRID],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createGrid: build.mutation<Grid, CreateDto<Grid>>({
        query: (body) => ({
          url: `${ENDPOINT_TAGS.GRID}`,
          method: API_METHODS.POST,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.GRID],
      }),

      findGrid: build.query<Grid[], FindDto<Grid>>({
        query: (params) => ({
          url: `${ENDPOINT_TAGS.GRID}/find`,
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.GRID],
      }),

      findOneGrid: build.query<Grid, FindDto<Grid>>({
        query: (params) => ({
          url: `${ENDPOINT_TAGS.GRID}/findOne`,
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.GRID],
      }),

      findGridById: build.query<Grid, { id: string }>({
        query: ({ id }) => ({
          url: `${ENDPOINT_TAGS.GRID}/findById/${id}`,
          method: API_METHODS.GET,
        }),
        providesTags: [ENDPOINT_TAGS.GRID],
      }),

      updateGrid: build.mutation<Grid, { id: string; body: UpdateDto<Grid> }>({
        query: ({ id, body }) => ({
          url: `${ENDPOINT_TAGS.GRID}/${id}`,
          method: API_METHODS.PATCH,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.GRID],
      }),

      deleteGrid: build.mutation<Grid[], { id: string }>({
        query: ({ id }) => ({
          url: `${ENDPOINT_TAGS.GRID}/${id}`,
          method: API_METHODS.DELETE,
        }),
        invalidatesTags: [ENDPOINT_TAGS.GRID],
      }),

      searchGrid: build.query<{data: Grid[], totalRecords?: number}, SearchGridInput>({
				query: (params) => ({
					url: `${ENDPOINT_TAGS.GRID}/search`,
					method: API_METHODS.GET,
					params
				}),
				providesTags: [ENDPOINT_TAGS.GRID]
			}),
    }),
    overrideExisting: false,
  });

export const {
  useCreateGridMutation,
  useDeleteGridMutation,
  useUpdateGridMutation,
  useFindGridQuery,
  useFindOneGridQuery,
  useFindGridByIdQuery,
  useSearchGridQuery
} = GridAPI;

export default GridAPI;
