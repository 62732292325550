import { baseAPI as api } from '../api.service';
import { API_METHODS, ENDPOINT_TAGS } from '../config/apiTags';
import { CreateDto } from "switch-trading-shared/modules/crud/base.service";
import { FindDto } from "switch-trading-shared/modules/crud/base.service";
import { OffTaker } from "switch-trading-shared/modules/crud/off-takers/off-taker.entity";
import { Unwrap } from "switch-trading-shared/modules/crud/base.service";
import { UpdateDto } from "switch-trading-shared/modules/crud/base.service";
import { SearchOffTakerInput } from "switch-trading-shared/modules/crud/off-takers/off-taker.dto";

const OffTakerAPI = api
  .enhanceEndpoints({
    addTagTypes: [ENDPOINT_TAGS.OFFTAKER],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createOfftaker: build.mutation<Unwrap<Unwrap<OffTaker>>, CreateDto<OffTaker>>({
        query: (body) => ({
          url: 'off-taker',
          method: API_METHODS.POST,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      findOfftaker: build.query<Unwrap<Unwrap<OffTaker>>[], FindDto<OffTaker>>({
        query: (params) => ({
          url: 'off-taker/find',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      findOneOfftaker: build.query<Unwrap<Unwrap<OffTaker>>, FindDto<OffTaker>>({
        query: (params) => ({
          url: 'off-taker/findOne',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      findOfftakerById: build.query<Unwrap<Unwrap<OffTaker>>, { id: string }>({
        query: ({ id }) => ({
          url: `off-taker/findById/${id}`,
          method: API_METHODS.GET,
        }),
        providesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      updateOfftaker: build.mutation<Unwrap<Unwrap<OffTaker>>, { id: string; body: UpdateDto<OffTaker> }>({
        query: ({ id, body }) => ({
          url: `off-taker/${id}`,
          method: API_METHODS.PATCH,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      deleteOfftaker: build.mutation<Unwrap<Unwrap<OffTaker>>[], { id: string }>({
        query: ({ id }) => ({
          url: `off-taker/${id}`,
          method: API_METHODS.DELETE,
        }),
        invalidatesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      searchOffTaker: build.query<{data: Unwrap<OffTaker>[], totalRecords?: number}, SearchOffTakerInput>({
				query: (params) => ({
					url: 'off-taker/search',
					method: API_METHODS.GET,
					params
				}),
				providesTags: [ENDPOINT_TAGS.OFFTAKER]
			}),
    }),
    overrideExisting: false,
  });

export const {
  useCreateOfftakerMutation,
  useDeleteOfftakerMutation,
  useUpdateOfftakerMutation,
  useFindOfftakerQuery,
  useFindOneOfftakerQuery,
  useFindOfftakerByIdQuery,
  useSearchOffTakerQuery,
} = OffTakerAPI;

export default OffTakerAPI;
