import { TradeGroup } from 'switch-trading-shared/modules/crud/trade-groups/trade-group.entity';
import { ENDPOINT_TAGS } from '../config/apiTags';
import BaseApiService, { ResultWithHttpCode, ResultWithValueAndHttpCode } from './base.service';

class TradeGroupApiService extends BaseApiService {
  constructor() {
    super(process.env.REACT_APP_BASE_API_URL);
  }

  public async getTradeGroups(params: {
    applicationGroup?: string;
    page?: number;
    limit?: number;
  }): Promise<ResultWithValueAndHttpCode<TradeGroup[]> | undefined> {
    const apiResult: ResultWithValueAndHttpCode<any> | undefined = await this.getWithParams<any>(
      `${ENDPOINT_TAGS.TRADE_GROUP}/find`,
      params
    );
    if (apiResult !== undefined) {
      if (!apiResult.isSuccess) {
        return await Promise.reject(
          new Error(
            JSON.stringify({
              isSuccess: false,
              value: null,
              errorMessage: apiResult.errorMessage,
              httpCode: apiResult.httpCode,
            })
          )
        );
      }
      return {
        isSuccess: true,
        value: apiResult.value,
        errorMessage: '',
        httpCode: apiResult.httpCode,
      };
    }
  }

  public async createTradeGroup(createTradeGroup: {}): Promise<ResultWithValueAndHttpCode<any> | undefined> {
    const apiResult: ResultWithValueAndHttpCode<any> | undefined = await this.post<any>(
      `${ENDPOINT_TAGS.TRADE_GROUP}`,
      createTradeGroup
    );
    if (apiResult !== undefined) {
      if (!apiResult.isSuccess) {
        return await Promise.reject(
          new Error(
            JSON.stringify({
              isSuccess: false,
              value: null,
              errorMessage: apiResult.errorMessage,
              httpCode: apiResult.httpCode,
            })
          )
        );
      }

      return {
        isSuccess: true,
        value: createTradeGroup,
        errorMessage: '',
        httpCode: apiResult.httpCode,
      };
    }
  }

  public async deleteTradeGroup(tradeGroupId: string): Promise<ResultWithHttpCode | undefined> {
    const apiResult: ResultWithHttpCode | undefined = await this.delete(`${ENDPOINT_TAGS.TRADE_GROUP}/${tradeGroupId}`);
    if (apiResult !== undefined) {
      if (!apiResult.isSuccess) {
        return await Promise.reject(
          new Error(
            JSON.stringify({
              isSuccess: false,
              value: null,
              errorMessage: apiResult.errorMessage,
              httpCode: apiResult.httpCode,
            })
          )
        );
      }
      return {
        isSuccess: true,
        errorMessage: '',
        httpCode: apiResult.httpCode,
      };
    }
  }

  public async updateTradeGroup(tradeGroup: TradeGroup): Promise<ResultWithValueAndHttpCode<any> | undefined> {
    const { _id } = tradeGroup;
    const apiResult: ResultWithValueAndHttpCode<any> | undefined = await this.patch<any>(
      `${ENDPOINT_TAGS.TRADE_GROUP}/${_id}`,
      tradeGroup
    );
    if (apiResult !== undefined) {
      if (!apiResult.isSuccess) {
        return await Promise.reject(
          new Error(
            JSON.stringify({
              isSuccess: false,
              value: null,
              errorMessage: apiResult.errorMessage,
              httpCode: apiResult.httpCode,
            })
          )
        );
      }
      return {
        isSuccess: true,
        value: apiResult.value,
        errorMessage: '',
        httpCode: apiResult.httpCode,
      };
    }
  }
}

export default TradeGroupApiService;
