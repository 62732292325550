import { useContext } from 'react';

import { HomeOutlined } from '@mui/icons-material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { alpha, Box, BoxProps, IconButton, lighten, styled, Tooltip, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserInfo from 'src/components/UserInfo/UserInfo';
import appConfig from 'src/config/appConfig';
import { SidebarContext } from 'src/context/SidebarContext';
import Breadcrumbs from 'switch-fe-shared/components/Breadcrumbs/Breadcrumbs';
import routes from '../../../router/router';
interface HeaderWrapperProps extends BoxProps {
  hasSidebar?: boolean;
}

const HeaderWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'hasSidebar' })<HeaderWrapperProps>(
  ({ theme, hasSidebar }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${
          theme.palette.mode === 'dark'
            ? alpha(lighten(theme.header.background ?? '#111633', 0.1), 0.5)
            : theme.header.background
        };
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        
        @media (min-width: ${theme.breakpoints.values[appConfig.breakMobileView]}px) {
          left: ${hasSidebar ? theme.sidebar.width : '0'}; // Adjust width based on hasSidebar prop
          width: auto;
        }
`
);

type HeaderProps = {
  hasSidebar?: boolean;
};

function Header({ hasSidebar = true }: HeaderProps) {
  const { sidebarToggle, toggleSidebar, closeSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
    // This will navigate back one step in the history.
  };

  return (
    <HeaderWrapper
      hasSidebar={hasSidebar}
      display='flex'
      alignItems='center'
      style={{ justifyContent: 'space-between' }}
      // sx={{
      //   boxShadow:
      //     theme.palette.mode === 'dark'
      //       ? `0 1px 0 ${alpha(
      //         lighten(theme.colors.primary.main, 0.7),
      //         0.15
      //       )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
      //       : `0px 2px 8px -3px ${alpha(theme.colors.alpha.black[100], 0.2)}, 0px 5px 22px -4px ${alpha(
      //         theme.colors.alpha.black[100],
      //         0.1
      //       )}`,
      // }}
    >
      <Box display='flex' alignItems='center' gap={1}>
        <IconButton onClick={handleGoHome} size='small'>
          <HomeOutlined fontSize='inherit' />
        </IconButton>
        <Breadcrumbs routes={routes} />
      </Box>

      <Box display='flex' alignItems='center'>
        {/* <HeaderButtons /> */}
        <UserInfo />
        <Box
          component='span'
          sx={{
            ml: 2,
            display: {
              [appConfig.breakMobileView]: 'none',
              xs: 'inline-block',
            },
          }}
        >
          <Tooltip arrow title='Toggle Menu'>
            <IconButton color='primary' onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon fontSize='small' /> : <CloseTwoToneIcon fontSize='small' />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
