import { useContext } from 'react';

import { DashboardOutlined, ElectricMeter, Hub, MonetizationOn, RequestQuote, Speed } from '@mui/icons-material';
import { Box, Button, Divider, List, ListItem, MenuItem, styled, useTheme } from '@mui/material';
import { FaLayerGroup } from 'react-icons/fa';
import { MdOutlineGrid3X3 } from 'react-icons/md';
import { TbSolarPanel2 } from 'react-icons/tb';
import { NavLink as RouterLink } from 'react-router-dom';
import { NetworkState } from 'src/constants';
import { SidebarContext } from 'src/context/SidebarContext';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { updateSelectedTradeGroup } from 'src/store/trade-group/trade-group.slice';
import { updateSelectedTrader } from 'src/store/trader/trader.slice';
import CustomTextField from 'switch-fe-shared/components/TextInput/Textfield';
import pages from '../../../../router/routes';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 6px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.palette.text.primary};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.sidebar.menuItemIconColor};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.sidebar.menuItemIconColor};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create(['transform', 'opacity'])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { closeSidebar } = useContext(SidebarContext);
  const {
    traderNetworkStatus: { traders: traderState },
    selectedTrader,
    traders,
  } = useAppSelector((state) => state.trader);
  const {
    tradeGroupNetworkStatus: { tradeGroups: tradeGroupState },
    selectedTradeGroup,
    tradeGroups,
  } = useAppSelector((state) => state.tradeGroup);

  return (
    <>
      <MenuWrapper>
        <SubMenuWrapper>
          <List component='div'>
            <ListItem component='div'>
              <CustomTextField
                value={traderState !== NetworkState.SUCCESS ? 'empty' : selectedTrader?._id ?? 'empty'}
                label='Trader'
                sx={{ width: '100%' }}
                select={true}
                onChange={(event) => {
                  dispatch(updateSelectedTrader(traders.find((t) => t._id === event.target.value)));
                }}
                disabled={traders.length === 0}
              >
                {traders.length > 0 ? (
                  traders.map((tradeGroup) => (
                    <MenuItem key={tradeGroup._id} value={tradeGroup._id}>
                      {tradeGroup.name}
                    </MenuItem>
                  ))
                ) : traderState === NetworkState.PENDING ? (
                  <MenuItem key={'loading'} value={'empty'} disabled>
                    Loading...
                  </MenuItem>
                ) : (
                  <MenuItem key={'empty'} value={'empty'} disabled>
                    No traders available
                  </MenuItem>
                )}
              </CustomTextField>
            </ListItem>
            <ListItem component='div' sx={{ my: theme.spacing(1) }}>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={pages.trader.tradingGroups.path}
                startIcon={<FaLayerGroup />}
              >
                Trading Groups
              </Button>
            </ListItem>
            <ListItem component='div'>
              <CustomTextField
                value={tradeGroupState !== NetworkState.SUCCESS ? 'empty' : selectedTradeGroup?.id ?? ''}
                label='Trade Groups'
                sx={{ width: '100%' }}
                select={true}
                onChange={(event) => {
                  dispatch(updateSelectedTradeGroup(tradeGroups.find((tg) => tg.id === event.target.value)));
                }}
                disabled={tradeGroups.length === 0}
                placeholder='No trade groups available'
              >
                {tradeGroups.length > 0 ? (
                  tradeGroups.map((tradeGroup) => (
                    <MenuItem key={tradeGroup.id} value={tradeGroup.id}>
                      {tradeGroup.name}
                    </MenuItem>
                  ))
                ) : tradeGroupState === NetworkState.PENDING ? (
                  <MenuItem key={'empty'} value={'empty'} disabled>
                    Loading...
                  </MenuItem>
                ) : (
                  <MenuItem key={'loading'} value={'empty'} disabled>
                    No trade groups available
                  </MenuItem>
                )}
              </CustomTextField>
            </ListItem>
            <Divider
              sx={{
                mt: theme.spacing(2),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            {selectedTradeGroup && (
              <>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={pages.trader.dashboard.path}
                    startIcon={<DashboardOutlined />}
                  >
                    Dashboard
                  </Button>
                </ListItem>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={`${pages.trader.tradingGroup.path}/${selectedTradeGroup.id}`}
                    startIcon={<Hub />}
                  >
                    Trade Group
                  </Button>
                </ListItem>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={pages.trader.grids.path}
                    startIcon={<MdOutlineGrid3X3 />}
                  >
                    Grids
                  </Button>
                </ListItem>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={pages.trader.generator.path}
                    startIcon={<TbSolarPanel2 />}
                  >
                    Generators
                  </Button>
                </ListItem>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={pages.trader.offTakers.path}
                    startIcon={<ElectricMeter />}
                  >
                    Off-Takers
                  </Button>
                </ListItem>
                {/* <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={pages.main.agreements.path}
                startIcon={<Description />}
              >
                Agreements
              </Button>
            </ListItem> */}
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={pages.trader.meters.path}
                    startIcon={<Speed />}
                  >
                    Meters
                  </Button>
                </ListItem>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={pages.trader.tariffs.path}
                    startIcon={<RequestQuote />}
                  >
                    Tariffs
                  </Button>
                </ListItem>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={pages.trader.recons.path}
                    startIcon={<MonetizationOn />}
                  >
                    Reconciliations
                  </Button>
                </ListItem>
                {/* <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={pages.trader.reports.path}
                    startIcon={<Article />}
                  >
                    Reports
                  </Button>
                </ListItem> */}
              </>
            )}
          </List>
        </SubMenuWrapper>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
