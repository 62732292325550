import { Box, Card, Container, Grid, Link, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import ThemeSwitch from 'src/components/ThemeSwitch/ThemeSwitch';
import pages from 'src/router/routes';
import ContentWrapper from 'switch-fe-shared/components/Wrappers/ContentWrapper';

const PageContent = () => {
  const [displayName, setDisplayName] = useState('');

  return (
    <Container maxWidth='lg' sx={{ textAlign: 'center' }}>
      <Grid spacing={{ xs: 6, md: 10 }} justifyContent='center' alignItems='center' container>
        <Grid item md={10} lg={8} mx='auto'>
          <Typography sx={{ mb: 2 }} variant='h1'>
            {/* {loading? <Skeleton /> : `Welcome ${displayName}. Your email address is unverified.`} */}
          </Typography>
          <Typography sx={{ lineHeight: 1.5, pb: 4 }} variant='h4' color='text.secondary' fontWeight='normal'>
            {/* {loading? <Skeleton /> : `Please go to your inbox and click on the verification link that was sent to ${user?.email ?? ''}. Once verified, you will be able to access the platform.`} */}
          </Typography>
          <Grid item md={10} lg={8} mx='auto' style={{ marginTop: 15 }}>
            <Typography variant='subtitle2'>
              {"Don't receive an email? "}
              <Link
                href='#'
                underline='hover'
                //   onClick={resendHandler}
              >
                {'Resend confirmation link. '}
              </Link>
            </Typography>
          </Grid>
          <Grid item md={10} lg={8} mx='auto' style={{ marginTop: 15 }}>
            <Typography variant='subtitle2'>
              {'Not your email address? '}
              <Link
                href='/login'
                underline='hover'
                //   onClick={logout}
              >
                {'Sign Out.'}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

function Unverified() {
  // const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();

  const navigatePathname = useMemo(() => {
    const state = location.state as { from: Location };

    if (state && state.from) {
      return state.from;
    }

    return pages.trader.dashboard.path;
  }, [location]);

  return (
    <ContentWrapper>
      <Helmet>
        <title>Verify Email</title>
      </Helmet>
      <Container maxWidth='lg'>
        <Box display='flex' justifyContent='center' py={5} alignItems='center'>
          {/* <Logo /> */}
          <Box style={{ position: 'absolute', top: 15, right: 15 }}>
            <ThemeSwitch />
          </Box>
        </Box>
        <Card sx={{ p: 10, mb: 10, borderRadius: 10 }}>
          Unverified
          {/* {loading? <CircularProgress /> : <PageContent />} */}
        </Card>
      </Container>
    </ContentWrapper>
  );
}

export default Unverified;
